<template>
  <c-box flex-grow="1">
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="primary.400"
          as="router-link"
          to="/management/diet-kuesioner"
        >
          Manajemen Diet Kuesioner
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          {{ id ? "Ubah" : "Tambah" }} Diet Kuesioner
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-heading
      as="h3"
      font-family="Montserrat"
      font-weight="bold"
      font-size="24px"
      line-height="36px"
      mb="50px"
    >
      {{ id ? "Ubah" : "Tambah" }} Diet Kuesioner
    </c-heading>

    <form
      v-chakra
      max-w="590px"
    >
      <c-form-control
        v-if="id"
        is-required
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          ID Question
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            :disabled="id"
            :variant="id ? 'filled' : ''"
            error-border-color="red.300"
            placeholder="Masukkan Question"
            height="62px"
            :value="id"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        mb="25px"
        is-required
        :is-invalid="category === ''"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Kategori Question
        </c-form-label>
        <c-input-group size="md">
          <c-select
            v-model="category"
            height="62px"
          >
            <option
              v-for="option in optionCategory"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </c-select>
        </c-input-group>
      </c-form-control>

      <c-form-control
        :is-invalid="question === ''"
        is-required
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Question
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="question"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Question"
            height="62px"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control mb="25px">
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Subtext (Instruksi Kepada Klien)
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="subtext"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Subtext (Instruksi Kepada Klien)"
            height="62px"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        :is-invalid="answerType === ''"
        is-required
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Jenis Jawaban
        </c-form-label>
        <c-input-group size="md">
          <c-select
            v-model="answerType"
            height="62px"
          >
            <option
              v-for="item in optionAnswerType"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </option>
          </c-select>
        </c-input-group>
      </c-form-control>

      <c-box
        v-if="
          answerType === 'dropdown' ||
            answerType === 'multiple_choice' ||
            answerType === 'checkboxes'
        "
      >
        <c-form-control
          v-for="(item, index) in answerContent"
          :key="index"
          mb="25px"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
            text-transform="capitalize"
          >
            Opsi {{ labelAnswerContent + " " + (index + 1) }}
          </c-form-label>
          <c-flex align-items="center">
            <c-input
              v-model="item.answer"
              type="text"
              error-border-color="red.300"
              placeholder="Masukkan Question"
              height="62px"
            />
            <c-button
              ml="18px"
              min-width="auto"
              variant-color="red"
              variant="link"
              @click="answerContent.splice(index, 1)"
            >
              <c-image
                :src="require('@/assets/icon-trash-line.svg')"
                alt="empty"
              />
            </c-button>
          </c-flex>
        </c-form-control>
        <c-button
          :is-disabled="disabledAddAnswer"
          variant-color="primary"
          variant="outline"
          border-radius="60px"
          h="62px"
          w="100%"
          mb="25px"
          left-icon="add"
          @click="addAnswerContent"
        >
          Tambah Opsi Baru
        </c-button>
      </c-box>

      <c-form-control mb="25px">
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Required or Not
        </c-form-label>
        <c-input-group size="md">
          <c-select
            v-model="isRequired"
            height="62px"
          >
            <option value="false">
              Not Required
            </option>
            <option value="true">
              Required
            </option>
          </c-select>
        </c-input-group>
      </c-form-control>

      <c-flex
        mt="28px"
        justify-content="space-between"
      >
        <c-button
          variant="solid"
          variant-color="gray"
          border-radius="60px"
          w="50%"
          h="62px"
          mr="20px"
          font-size="18px"
          color="#555555"
          @click="onCancel"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          border-radius="60px"
          w="50%"
          h="62px"
          font-size="18px"
          :is-disabled="isDisabledSubmit"
          @click="onSubmit"
        >
          Simpan
        </c-button>
      </c-flex>
    </form>
  </c-box>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'

export default {
  name: 'SuperadminDietKuesionerForm',
  components: {},
  mixins: [generalMixin],
  data() {
    return {
      category: 'informed_consent',
      question: null,
      subtext: '',
      answerType: 'dropdown',
      answerContent: [],
      isRequired: 'false',
    }
  },
  computed: {
    ...mapState({
      item: (s) => s.suKuisioner.item,
      optionAnswerType: (s) => s.suKuisioner.optionAnswerType,
      optionCategory: (s) => s.suKuisioner.optionCategory,
    }),
    id() {
      return this.$route.params.id
    },
    labelAnswerContent() {
      return this.optionAnswerType.find((it) => it.value === this.answerType)
        ?.label
    },
    disabledAddAnswer() {
      let emptyObj = this.answerContent.findIndex((dt) => !dt.answer) > -1
      if (emptyObj) return true
      return false
    },
    isDisabledSubmit() {
      if (
        !this.category ||
        !this.question ||
        !this.answerType ||
        !this.answerContent.length === 0
      )
        return true
      return false
    },
  },
  watch: {
    item: {
      handler(val) {
        if (val) {
          this.category = val.category
          this.question = val.question
          this.subtext = val.subtext
          this.answerType = val.answerType
          this.answerContent = val.answerContent
          this.isRequired = val.isRequired.toString()
        }
      },
    },
    answerContent: {
      immediate: true,
      handler(val) {
        if (val.length === 0) {
          this.answerContent.push({ answer: null })
        }
      },
    },
  },
  async mounted() {
    if (!this.id) return
    this.detailKuesioner(this.id)
  },
  methods: {
    ...mapActions({
      createKuesioner: 'suKuisioner/createKuesionerSuperadmin',
      detailKuesioner: 'suKuisioner/detailKuesionerSuperadmin',
      updateKuesioner: 'suKuisioner/updateKuesionerSuperadmin',
    }),
    addAnswerContent() {
      this.answerContent.push({ answer: null })
    },
    onSubmit() {
      let params = {
        category: this.category,
        question: this.question,
        subtext: this.subtext,
        answerType: this.answerType,
        answerContent: this.answerContent,
        isRequired: this.isRequired,
      }
      if (this.id) {
        params.id = this.id
        return this.updateKuesioner(params)
          .then(() => {
            this.$toast({
              title: 'Success',
              description: 'Update diet kuesioner is successfully',
              status: 'success',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
            this.$router.push({
              name: 'superadmin.diet-kuesioner.detail',
              params: { id: this.id },
            })
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
      }
      return this.createKuesioner(params)
        .then(() => {
          this.$toast({
            title: 'Success',
            description: 'Create diet kuesioner is successfully',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'superadmin.diet-kuesioner',
            query: { type: this.type },
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
    },
    onCancel() {
      if (this.id) {
        this.$router.push({
          name: 'superadmin.diet-kuesioner.detail',
          params: { id: this.id },
        })
      } else {
        this.$router.push({ name: 'superadmin.diet-kuesioner' })
      }
    },
  },
}
</script>
