var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": "/management/diet-kuesioner"
    }
  }, [_vm._v(" Manajemen Diet Kuesioner ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Ubah" : "Tambah") + " Diet Kuesioner ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px",
      "mb": "50px"
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Ubah" : "Tambah") + " Diet Kuesioner ")]), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "max-w": "590px"
    }
  }, [_vm.id ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" ID Question ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "disabled": _vm.id,
      "variant": _vm.id ? 'filled' : '',
      "error-border-color": "red.300",
      "placeholder": "Masukkan Question",
      "height": "62px",
      "value": _vm.id
    }
  })], 1)], 1) : _vm._e(), _c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.category === ''
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kategori Question ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "height": "62px"
    },
    model: {
      value: _vm.category,
      callback: function callback($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  }, _vm._l(_vm.optionCategory, function (option) {
    return _c('option', {
      key: option.value,
      domProps: {
        "value": option.value
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")]);
  }), 0)], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.question === '',
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Question ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Question",
      "height": "62px"
    },
    model: {
      value: _vm.question,
      callback: function callback($$v) {
        _vm.question = $$v;
      },
      expression: "question"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Subtext (Instruksi Kepada Klien) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Subtext (Instruksi Kepada Klien)",
      "height": "62px"
    },
    model: {
      value: _vm.subtext,
      callback: function callback($$v) {
        _vm.subtext = $$v;
      },
      expression: "subtext"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.answerType === '',
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Jenis Jawaban ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "height": "62px"
    },
    model: {
      value: _vm.answerType,
      callback: function callback($$v) {
        _vm.answerType = $$v;
      },
      expression: "answerType"
    }
  }, _vm._l(_vm.optionAnswerType, function (item) {
    return _c('option', {
      key: item.value,
      domProps: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0)], 1)], 1), _vm.answerType === 'dropdown' || _vm.answerType === 'multiple_choice' || _vm.answerType === 'checkboxes' ? _c('c-box', [_vm._l(_vm.answerContent, function (item, index) {
    return _c('c-form-control', {
      key: index,
      attrs: {
        "mb": "25px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" Opsi " + _vm._s(_vm.labelAnswerContent + " " + (index + 1)) + " ")]), _c('c-flex', {
      attrs: {
        "align-items": "center"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Question",
        "height": "62px"
      },
      model: {
        value: item.answer,
        callback: function callback($$v) {
          _vm.$set(item, "answer", $$v);
        },
        expression: "item.answer"
      }
    }), _c('c-button', {
      attrs: {
        "ml": "18px",
        "min-width": "auto",
        "variant-color": "red",
        "variant": "link"
      },
      on: {
        "click": function click($event) {
          return _vm.answerContent.splice(index, 1);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty"
      }
    })], 1)], 1)], 1);
  }), _c('c-button', {
    attrs: {
      "is-disabled": _vm.disabledAddAnswer,
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "60px",
      "h": "62px",
      "w": "100%",
      "mb": "25px",
      "left-icon": "add"
    },
    on: {
      "click": _vm.addAnswerContent
    }
  }, [_vm._v(" Tambah Opsi Baru ")])], 2) : _vm._e(), _c('c-form-control', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Required or Not ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "height": "62px"
    },
    model: {
      value: _vm.isRequired,
      callback: function callback($$v) {
        _vm.isRequired = $$v;
      },
      expression: "isRequired"
    }
  }, [_c('option', {
    attrs: {
      "value": "false"
    }
  }, [_vm._v(" Not Required ")]), _c('option', {
    attrs: {
      "value": "true"
    }
  }, [_vm._v(" Required ")])])], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "28px",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "gray",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "mr": "20px",
      "font-size": "18px",
      "color": "#555555"
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "font-size": "18px",
      "is-disabled": _vm.isDisabledSubmit
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }